/* FONTS ✒️ */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght@0,6..12,75..125,200..1000;1,6..12,75..125,200..1000&display=swap");

/* GLOBAL STYLES */
* {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}
a {
  color: inherit;
}

html,
#root {
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  min-height: 100%;
}

html::-webkit-scrollbar,
#root::-webkit-scrollbar {
  display: none;
}

#root {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  font-family: "Nunito Sans", sans-serif;
  padding: 0px;
  margin: 0px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  overflow-x: hidden;
}

/* LAYOUT */
.center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
}

/* Make sure app container takes full width */
.app-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes float {
  0% {
    transform: translateY(0vh);
    opacity: 1;
  }
  50% {
    transform: translateY(1vh);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0vh);
    opacity: 1;
  }
}

.scroll-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -0.5rem;
}
.scroll-indicator span {
  font-size: 1.2rem;
  color: #fff3;
  animation: float 10s ease-in-out infinite;
  z-index: 100;
  font-weight: 100;
  pointer-events: none;
  margin-top: -3rem;
}
/* EFFECTS */
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
      circle at 20% 20%,
      rgba(255, 182, 193, 0.15) 0%,
      transparent 50%
    ),
    radial-gradient(
      circle at 80% 80%,
      rgba(173, 216, 230, 0.15) 0%,
      transparent 50%
    );
  filter: blur(40px);
  z-index: -1;
  pointer-events: none;
}

.effects-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
      circle at 70% 30%,
      rgba(255, 215, 0, 0.15) 0%,
      transparent 50%
    ),
    radial-gradient(
      circle at 30% 70%,
      rgba(147, 112, 219, 0.15) 0%,
      transparent 50%
    );
  filter: blur(40px);
  z-index: -1;
  pointer-events: none;
}
